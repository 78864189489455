import { gql } from '@apollo/client';
import {
  ORDER_FRAGMENT,
  ORDER_PRODUCT_FRAGMENT,
  CATEGORY_FRAGMENT,
  APPOINTMENT_FRAGMENT,
  ADDRESS_FRAGMENT,
  LOCATION_FRAGMENT,
  USER_FRAGMENT,
  PRODUCT_FRAGMENT,
  TAX_FRAGMENT,
  VARIANT_FRAGMENT,
  VEHICLE_FRAGMENT,
  CLIENT_FRAGMENT,
  DISCOUNT_FRAGMENT,
  REPAIR_FRAGMENT,
  CURRENCY_FRAGMENT,
  SPECS_FRAGMENT,
  ACCOUNT_FRAGMENT,
} from './fragments';

export const GET_CATEGORIES = gql`
  query GET_CATEGORIES($skip: Int, $limit: Int) {
    categories(skip: $skip, limit: $limit) {
      ...CategoryFragment
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_VEHICLES = gql`
  query GET_VEHICLES($filter: FilterFindManyVehicleInput) {
    vehicles(filter: $filter) {
      ...VehicleFragment
    }
  }
  ${VEHICLE_FRAGMENT}
`;

export const GET_BRANDS = gql`
  query GET_BRANDS($sort: SortFindManyVehicleBrandInput) {
    brands(sort: $sort) {
      _id
      name
      active
    }
  }
`;
export const GET_MODELS_BY_BRAND = gql`
  query GET_MODELS_BY_BRAND($data: GetAllByBrandInput) {
    getModelsByBrand(data: $data) {
      _id
      name
      active
    }
  }
`;

export const GET_BY_MODEL_YEAR_BRAND = gql`
  query GET_BY_MODEL_YEAR_BRAND($data: GetByModelYearAndBrandInput) {
    getByModelYearAndBrand(data: $data) {
      _id
      engine {
        transmission
        volume
        cylinders
      }
      year
    }
  }
`;

export const GET_CLIENT = gql`
  query GET_CLIENT($filter: FilterFindOneClientInput!) {
    client(filter: $filter) {
      ...ClientFragment
    }
  }
  ${CLIENT_FRAGMENT}
`;

export const GET_CLIENTS = gql`
  query GET_CLIENTS($filter: FilterFindManyUserInput) {
    users(filter: $filter) {
      _id
      firstName
      lastName
      email
      dni
      dniType
      phone {
        code
        phone
      }
      client {
        _id
      }
    }
  }
`;

export const GET_USERS = gql`
  query GET_USERS(
    $filter: FilterFindManyUserInput
    $sort: SortFindManyUserInput
  ) {
    users(filter: $filter, sort: $sort) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_USER_CLIENT = gql`
  query User($filter: FilterFindOneUserInput) {
    user(filter: $filter) {
      _id
      firstName
      lastName
      email
      dni
      dniType
      phone {
        code
        phone
      }
      client {
        _id
      }
    }
  }
`;

export const GET_LOCATIONS = gql`
  query GET_LOCATIONS(
    $filter: FilterFindManyLocationInput
    $sort: SortFindManyLocationInput
  ) {
    locations(filter: $filter, sort: $sort) {
      _id
      name
      active
    }
  }
`;

export const GET_USER = gql`
  query GET_USER($filter: FilterFindOneUserInput!) {
    user(filter: $filter) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_PRODUCT = gql`
  query GET_PRODUCT($filter: FilterFindOneProductInput!) {
    product(filter: $filter) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCTS = gql`
  query GET_PRODUCTS($data: GetProductsInput) {
    getProducts(data: $data) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_USERS_LIST = gql`
  query GET_USERS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyUserInput
    $sort: SortFindManyUserInput
  ) {
    userPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      items {
        _id
        firstName
        lastName
        email
        createdAt
        active
        userType
        location {
          name
        }
        shift
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_ORDER_PRODUCTS_LIST = gql`
  query GET_ORDER_PRODUCTS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyOrderProductInput
  ) {
    orderProductPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...OrderProductFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${ORDER_PRODUCT_FRAGMENT}
`;

export const GET_LOCATIONS_LIST = gql`
  query GET_LOCATIONS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyLocationInput
  ) {
    locationPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...LocationFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${LOCATION_FRAGMENT}
`;

export const GET_PRODUCTS_LIST = gql`
  query GET_PRODUCTS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyProductInput
  ) {
    productPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        _id
        title
        categories {
          title
        }
        status
        active
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_LOCATION = gql`
  query GET_LOCATION($filter: FilterFindOneLocationInput!) {
    location(filter: $filter) {
      ...LocationFragment
    }
  }
  ${LOCATION_FRAGMENT}
`;

export const GET_ORDER_PRODUCT = gql`
  query GET_ORDER_PRODUCT($filter: FilterFindOneOrderProductInput!) {
    orderProduct(filter: $filter) {
      ...OrderProductFragment
    }
  }
  ${ORDER_PRODUCT_FRAGMENT}
`;

export const GET_ADDRESSES = gql`
  query GET_ADDRESSES(
    $filter: FilterFindManyAddressInput
    $sort: SortFindManyAddressInput
  ) {
    addresses(filter: $filter, sort: $sort) {
      ...AddressFragment
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const GET_ORDERS_LIST = gql`
  query GET_ORDERS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyOrderInput
  ) {
    orderPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...OrderFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const GET_VARIANTS_LIST = gql`
  query GET_VARIANTS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyVariantInput
  ) {
    variantPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        _id
        stock
        active
        location {
          name
        }
        option {
          option1
          option2
          option3
        }
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_VARIANT = gql`
  query GET_VARIANT($filter: FilterFindOneVariantInput!) {
    variant(filter: $filter) {
      ...VariantFragment
    }
  }
  ${VARIANT_FRAGMENT}
`;

export const GET_TAXES_LIST = gql`
  query GET_TAXES_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyTaxInput
  ) {
    taxPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...TaxFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${TAX_FRAGMENT}
`;

export const GET_TAX = gql`
  query GET_TAX($filter: FilterFindOneTaxInput!) {
    tax(filter: $filter) {
      ...TaxFragment
    }
  }
  ${TAX_FRAGMENT}
`;

export const GET_TAXES = gql`
  query GET_TAXES(
    $filter: FilterFindManyTaxInput
    $sort: SortFindManyTaxInput
  ) {
    taxes(filter: $filter, sort: $sort) {
      ...TaxFragment
    }
  }
  ${TAX_FRAGMENT}
`;

export const GET_YEARS_BY_MODELS = gql`
  query GET_YEARS_BY_MODELS($data: GetByModelInput) {
    getYearsByModel(data: $data) {
      years
    }
  }
`;

export const GET_ALL_BY_MODEL = gql`
  query GET_ALL_BY_MODEL($data: GetByModelInput) {
    getAllByModel(data: $data) {
      _id
      engine {
        transmission
        volume
        cylinders
      }
      year
    }
  }
`;

export const GET_BY_MODEL_AND_YEAR = gql`
  query GET_BY_MODEL_AND_YEAR($data: GetByModelAndYearInput) {
    getByModelAndYear(data: $data) {
      _id
      engine {
        transmission
        volume
        cylinders
        valves
      }
      year
    }
  }
`;

export const GET_CATEGORIES_LIST = gql`
  query GET_CATEGORIES_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyCategoryInput
  ) {
    categoryPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...CategoryFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const GET_DISCOUNTS_LIST = gql`
  query GET_DISCOUNTS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyDiscountInput
  ) {
    discountPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...DiscountFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const GET_DISCOUNT = gql`
  query GET_DISCOUNT($filter: FilterFindOneDiscountInput!) {
    discount(filter: $filter) {
      ...DiscountFragment
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const GET_DISCOUNTS = gql`
  query GET_DISCOUNTS(
    $filter: FilterFindManyDiscountInput
    $sort: SortFindManyDiscountInput
  ) {
    discounts(filter: $filter, sort: $sort) {
      ...DiscountFragment
    }
  }
  ${DISCOUNT_FRAGMENT}
`;

export const GET_ORDER = gql`
  query GET_ORDER($filter: FilterFindOneOrderInput!) {
    order(filter: $filter) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const GET_REPAIR = gql`
  query GET_REPAIR($data: GetRepair) {
    getRepair(data: $data) {
      repair {
        ...RepairFragment
      }
      mechanic {
        ...UserFragment
      }
      vehicle {
        ...VehicleFragment
      }
    }
  }
  ${REPAIR_FRAGMENT}
  ${USER_FRAGMENT}
  ${VEHICLE_FRAGMENT}
`;

export const GET_VEHICLE = gql`
  query GET_VEHICLE($filter: FilterFindOneVehicleInput!) {
    vehicle(filter: $filter) {
      ...VehicleFragment
    }
  }
  ${VEHICLE_FRAGMENT}
`;

export const GET_APPOINTMENTS_LIST = gql`
  query GET_APPOINTMENTS_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyAppointmentInput
    $sort: SortFindManyAppointmentInput
  ) {
    appointmentPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      items {
        reservationNumber
        date
        user {
          firstName
          lastName
        }
        location {
          name
        }
        show
        active
        _id
        createdAt
        order {
          _id
          total
          paymentStatus
        }
        status
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query GET_CATEGORY($filter: FilterFindOneCategoryInput!) {
    category(filter: $filter) {
      ...CategoryFragment
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const GET_APPOINTMENTS = gql`
  query GET_APPOINTMENTS($sort: SortFindManyAppointmentInput) {
    appointments(filter: $filter, sort: $sort) {
      ...AppointmentFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const GET_ALL_APPOINTMENTS_BY_LOCATION = gql`
  query GET_ALL_APPOINTMENTS_BY_LOCATION(
    $data: GetAppointmentsByLocationInput
  ) {
    getAllAppointmentsByLocations(data: $data) {
      _id
      date
      status
      show
      user {
        firstName
        lastName
        photo {
          src
        }
      }
      order {
        _id
      }
      vehicle {
        model {
          name
        }
      }
      services {
        name
      }
      location {
        name
      }
    }
  }
`;

export const GET_AVAILABLE_SLOTS = gql`
  query GET_AVAILABLE_SLOTS($data: GetAvailableSlots) {
    getAvailableSlots(data: $data) {
      slots {
        hour
        available
        count
      }
    }
  }
`;

export const GET_APPOINTMENT = gql`
  query GET_APPOINTMENT($filter: FilterFindOneAppointmentInput) {
    appointment(filter: $filter) {
      ...AppointmentFragment
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const GET_ALL_APPOINTMENT_BY_LOCATION = gql`
  query GET_ALL_APPOINTMENT_BY_LOCATION($data: GetAppointmentsByLocationInput) {
    getAllAppointmentsByLocations(data: $data) {
      date
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  query GET_ALL_PRODUCTS(
    $filter: FilterFindManyProductInput
    $sort: SortFindManyProductInput
  ) {
    products(filter: $filter, sort: $sort) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_SHOPCART_TALLY = gql`
  query GET_SHOPCART_TALLY($data: GetShopcartTallyInput) {
    getShopcartTally(data: $data) {
      subtotal
      taxableSubtotal
      realTax
      total
      discountTotal
      totalTax
    }
  }
`;

export const GET_VARIANTS = gql`
  query GET_VARIANTS($sort: SortFindManyVariantInput) {
    variants(filter: $filter, sort: $sort) {
      ...VariantFragment
    }
  }
  ${VARIANT_FRAGMENT}
`;

export const GET_CURRENCY = gql`
  query GET_CURRENCY($filter: FilterFindOneCurrencyInput) {
    currency(filter: $filter) {
      ...CurrencyFragment
    }
  }
  ${CURRENCY_FRAGMENT}
`;

export const GET_CURRENCIES_LIST = gql`
  query GET_CURRENCIES_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyCurrencyInput
  ) {
    currencyPagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...CurrencyFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${CURRENCY_FRAGMENT}
`;

export const CUSTOM_GET_PRODUCTS = gql`
  query CUSTOM_GET_PRODUCTS($data: CustomGetProductsInput) {
    customGetProducts(data: $data) {
      oilTag {
        ...ProductFragment
      }
      refrigerantTag {
        ...ProductFragment
      }
      wipeTag {
        ...ProductFragment
      }
      serviceTag {
        ...ProductFragment
      }
      hydraulicOilTag {
        ...ProductFragment
      }
      flushTag {
        ...ProductFragment
      }
      serviceFlushTag {
        ...ProductFragment
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_CURRENCIES = gql`
  query GET_CURRENCIES($filter: FilterFindManyCurrencyInput) {
    currencies(filter: $filter) {
      ...CurrencyFragment
    }
  }
  ${CURRENCY_FRAGMENT}
`;

export const GET_ORDERS_WITHOUT_APPOINTMENT = gql`
  query GET_ORDERS_WITHOUT_APPOINTMENT($data: GetOrdersWithoutAppointment) {
    getOrdersWithoutAppointment(data: $data) {
      count
      items {
        _id
        user {
          firstName
          lastName
        }
        location {
          name
        }
        total
        active
        createdAt
      }
      pageInfo {
        currentPage
        perPage
        itemCount
        pageCount
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const VALIDATE_NEXT_BLOCK = gql`
  query VALIDATE_NEXT_BLOCK($data: GetAvailableSlots) {
    validateNextBlock(data: $data) {
      success
    }
  }
`;

export const FIND_USERS_BY_PLATE = gql`
  query FIND_USERS_BY_PLATE($data: FindUserByPlateInput) {
    findUsersByPlate(data: $data) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_VEHICLE_BY_PLATE = gql`
  query GET_VEHICLE_BY_PLATE(
    $filter: FilterFindOneVehicleInput
    $skip: Int
    $sort: SortFindOneVehicleInput
  ) {
    vehicle(filter: $filter, skip: $skip, sort: $sort) {
      owner
      token
      _id
    }
  }
`;

export const GET_VEHICLE_LAST_APPOINTMENT = gql`
  query GET_VEHICLE_LAST_APPOINTMENT($data: GetVehicleLastAppointmentInput) {
    getVehicleLastAppointment(data: $data) {
      appointment {
        ...AppointmentFragment
      }
      superFlush
    }
  }
  ${APPOINTMENT_FRAGMENT}
`;

export const GET_CLIENT_VEHICLES = gql`
  query GET_CLIENT_VEHICLES(
    $filter: FilterFindOneClientInput
    $skip: Int
    $sort: SortFindOneClientInput
  ) {
    client(filter: $filter, skip: $skip, sort: $sort) {
      vehicles {
        ...VehicleFragment
      }
    }
  }
  ${VEHICLE_FRAGMENT}
`;

export const GET_VEHICLE_DEFAULT_SPECS = gql`
  query GetVehicleDefaultSpecs($data: GetVehicleDefaultSpecs) {
    getVehicleDefaultSpecs(data: $data) {
      specs {
        ...SpecsFragment
      }
    }
  }
  ${SPECS_FRAGMENT}
`;

export const GET_VEHICLE_SUGGESTIONS = gql`
  query GetVehicleSuggestions($data: GetVehicleSuggestions) {
    getVehicleSuggestions(data: $data) {
      service {
        ...ProductFragment
      }
      screenWashers {
        ...ProductFragment
      }
      oils {
        ...ProductFragment
      }
      oilFilters {
        ...ProductFragment
      }
      flushes {
        ...ProductFragment
      }
      coolants {
        ...ProductFragment
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_VEHICLES_LIST = gql`
  query GET_VEHICLES_LIST(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyVehicleInput
  ) {
    vehiclePagination(page: $page, perPage: $perPage, filter: $filter) {
      items {
        ...VehicleFragment
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${VEHICLE_FRAGMENT}
`;

export const FIND_VEHICLE_BY_ID = gql`
  query FIND_VEHICLE_BY_ID($data: FindVehicleByIdInput) {
    findVehicleById(data: $data) {
      inmaInfo {
        brandCode
        modelCode
        brandId
        modelId
        versionId
      }
    }
  }
`;

export const ACCOUNT_PAGINATION = gql`
  query ACCOUNT_PAGINATION(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyAccountInput
    $sort: SortFindManyAccountInput
  ) {
    accountPagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      items {
        _id
        status
        expiredDate
        balance
        appointments {
          date
          vehicle {
            plate
            model {
              name
            }
            brand {
              name
            }
          }
          order {
            total
          }
        }
        createdAt
        updatedAt
      }
      count
      pageInfo {
        currentPage
        perPage
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const COMPANY_BALANCES = gql`
  query COMPANY_BALANCES {
    companyBalances {
      balances {
        balance
        company {
          ...UserFragment
        }
        lastCutoffDate
      }
    }
  }
  ${USER_FRAGMENT}
`;
export const GET_ONE_PRODUCT = gql`
  query GET_ONE_PRODUCT($data: GetOneProductInput!) {
    getOneProduct(data: $data) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

export const GET_PRODUCTS_BY_TAGS = gql`
  query GetProductsByTags($data: GetProductsByTagsInput) {
    getProductsByTags(data: $data) {
      products {
        _id
        title
        variants {
          _id
          option {
            option1
            option2
            option3
          }
          active
          title
        }
      }
    }
  }
`;
